import * as React from "react";

import { SecondaryPageTitle, SeriousTalk } from "../components/shared/secondary-page-elements";

import DownloadModal from "../components/shared/download-modal";
import Footer from "../components/sections/footer";
import Grid from "../components/shared/grid";
import { H4 } from "../components/shared/headings";
import Layout from "../components/shared/layout";
import { Link } from "gatsby";
import Navbar from "../components/navbar";
import Section from "../components/shared/section";
import Seo from "../components/shared/seo";
import styled from "styled-components";

const SecurityHallOfFamePage = () => {
  return (
    <Layout>
        <Seo 
          title={"Hall of Fame | Copilot"}
        />
        <DownloadModal/>
        <Navbar/>
        <Section>
            <Grid>
                <SecondaryPageTitle>Hall of Fame</SecondaryPageTitle>
                <Subhead>The following is a list of all researchers who have contributed to Copilot's security:</Subhead>
                <SeriousTalk>
                  <ul>
                    <li><a target="_blank" href="https://www.linkedin.com/in/muhammad-kamran-javed-1859741ba" rel="external nofollow noopener noreferrer">Muhammad Kamran</a></li>
                  </ul>
                </SeriousTalk>
                <SeriousTalk>
                  Your name could be here. Make sure to check first our <Link to="/security-policy">security policy</Link>.
                </SeriousTalk>
            </Grid>
        </Section>
        <Footer/>
    </Layout>
  )
}

const Subhead = styled(H4)`
    grid-column: col-start / span 12;
    text-align: left;
    max-width: none;
    font-weight: 600;
    ${props => props.theme.device.tablet} {
      margin-bottom: 24px;
      grid-column: 3 / span 8;
      line-height: 1.2;
    }
    ${props => props.theme.device.laptop} {
        margin-bottom: 24px;
        grid-column: 4 / span 6;
    }
    ${props => props.theme.device.laptopLg} {
      margin-bottom: 24px;
      line-height: 1.2;
    }
`

export default SecurityHallOfFamePage
